import React from 'react';
import { HashRouter as Router, Route } from "react-router-dom";

import FrontPage from './pages/frontpage'

function App() {
  return (
    <Router>
       <Route path="/" exact component={FrontPage} />
    </Router>
  );
}

export default App;
