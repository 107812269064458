import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'
import {faDiscord, faTelegram, faTwitter} from '@fortawesome/free-brands-svg-icons'
import Sociocon from '../../components/sociocon'

class FrontPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            woffyUrl: "/assets/woffy.png",
            bgUrl: "/assets/background.png",
            popupTitle: "frontpage-popup-info frontpage-popup-info-animated",
        }
    }

    scrollAndHideMe = () => {
        const {popupTitle} = this.state
        window.scrollTo(0, ReactDOM.findDOMNode(this.refs.left).offsetTop);
        this.setState({
            popupTitle: popupTitle + ' frontpage-popup-info-hidden'
        })
    }

    render() {
        const {woffyUrl, bgUrl, popupTitle} = this.state
        return (
            <div className="frontpage">
                <div className="frontpage-main">
                    <div ref="left" className="frontpage-main-left">
                        <div className="frontpage-main-left-content">
                        <h1 className="frontpage-heading">NerdyWoffy</h1>
                        <h2 className="frontpage-subheading">Your usual wolf friend but slightly nerd!</h2>
                        <div className="frontpage-main-left-sociocons">
                            <Sociocon faIcon={faTelegram} url="https://telegram.dog/nerdywoffy">
                            nerdywoffy
                            </Sociocon>

                            <Sociocon faIcon={faDiscord} url="https://discordapp.com/users/133218454852337664">
                            NerdyWoffy#1984
                            </Sociocon>

                            <Sociocon faIcon={faTwitter} url="https://www.twitter.com/nerdywoffy">
                            nerdywoffy
                            </Sociocon>

                        </div>
                        </div>
                    </div>

                    <div className="frontpage-main-right">
                    <img className="frontpage-sideimage frontpage-main-right-image-right frontpage-main-right-background" alt="Background" src={bgUrl} />
                    <img className="frontpage-sideimage frontpage-main-right-image-right frontpage-main-right-woffy" alt="Woffy :3" src={woffyUrl} />
                    
                    <span onClick={this.scrollAndHideMe} className={popupTitle}>
                        <FontAwesomeIcon icon={faChevronCircleDown} /></span>
                    </div>
                </div>
            </div>
        );
    }
}

export default FrontPage;