import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'

class Sociocon extends Component {

    clicked = () => {
        if(this.props.url) {
            window.open(this.props.url || "#", '_blank');
        }
    }

    render() {
        return (
            <div onClick={this.clicked} className="sociocon">
                <div className="sociocon-container sociocon-container-icon">
                <FontAwesomeIcon className="sociocon-icon" icon={this.props.faIcon} />
                </div>
                <div className="sociocon-container sociocon-container-text">
                <span>{this.props.children}</span>
                </div>
            </div>
        );
    }
}

Sociocon.propTypes = {
    faIcon: PropTypes.object,
    url: PropTypes.string
};

export default Sociocon;